.app-store-section {
  background: rgba(237, 57, 70, 1);
  // background: linear-gradient(
  //   90deg,
  //   rgba(237, 57, 70, 1) 75%,
  //   rgba(0, 0, 0, 1) 25%
  // );
  // background: linear-gradient(
  //   90deg,
  //   rgba(199, 162, 255, 1) 75%,
  //   rgba(118, 158, 222, 1) 75%,
  //   rgba(109, 146, 213, 1) 80%,
  //   rgba(109, 146, 213, 1) 88%
  // );

  @media (max-width: 768px) {
    background: rgba(237, 57, 70, 1);
  }

  & h3 {
    line-height: 50px;
  }

  & .app-badge {
    max-width: 200px;
  }
}
