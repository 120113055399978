.course-slider,
.category-course-slider {
  border-radius: 16px !important;
  .swiper-slide {
    border-radius: 16px !important;
    // background-color: black;
    // -webkit-transition: opacity 600ms, visibility 600ms;
    // transition: opacity 600ms linear, visibility 600ms linear;
    img {
      display: block;
      border-radius: 16px;
      opacity: 0.4;
      width: 100%;
    }
    .overlay-dark {
      border-radius: 16px;
      background-color: black;
    }
  }

  .slider-content {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    top: 5%;
    left: 0;
    width: 80%;
    padding-left: 5%;
    color: #ffffff;

    p {
      line-height: 35px;
      @media (max-width: 768px) {
        line-height: 28px;
      }
    }
    .bottom-items {
      display: flex;
      justify-content: right;
    }
  }
}

.category-course-swiper {
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    background-image: url("../../assets/arrow_only.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    border: none;
    background-color: white;
    transform: rotate(180deg);
    -webkit-box-shadow: -2px -4px 18px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -2px -4px 18px -10px rgba(0, 0, 0, 0.75);
    box-shadow: -2px -4px 18px -10px rgba(0, 0, 0, 0.75);
    background-color: #ffffff;
    cursor: pointer;
    left: 18%;
    right: auto;
    top: 30%;

    @media (max-width: 1022px) {
      left: 1%;
    }
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    background-image: url("../../assets/arrow_only.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    border: none;
    background-color: white;
    -webkit-box-shadow: -2px -4px 18px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -2px -4px 18px -10px rgba(0, 0, 0, 0.75);
    box-shadow: -2px -4px 18px -10px rgba(0, 0, 0, 0.75);
    background-color: #ffffff;
    cursor: pointer;
    right: 18%;
    left: auto;
    top: 30%;
    @media (max-width: 1022px) {
      right: 1%;
    }
  }
}

.course-details-container {
  margin: 0 0.75rem;
  opacity: 0;
  visibility: hidden;
  .course-header {
    display: block;
  }

  .slider-controller {
    display: flex;
    justify-content: flex-end;

    .controller {
      background-image: url("../../assets/arrow_only.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      margin: 0.6rem 0.3rem;
      border: none;
      // outline: none;
    }

    .previous-slide {
      transform: rotate(180deg);
      -webkit-box-shadow: -2px -4px 18px -10px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: -2px -4px 18px -10px rgba(0, 0, 0, 0.75);
      box-shadow: -2px -4px 18px -10px rgba(0, 0, 0, 0.75);
      background-color: #ffffff;
      cursor: pointer;
    }
    .next-slide {
      -webkit-box-shadow: -2px 4px 18px -10px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: -2px 4px 18px -10px rgba(0, 0, 0, 0.75);
      box-shadow: -2px 4px 18px -10px rgba(0, 0, 0, 0.75);
      background-color: #ffffff;
      cursor: pointer;
    }
  }
}
.category-courses-bottom {
  opacity: 0;
  visibility: hidden;
}
.swiper-slide-active {
  & :hover {
    .content-hide {
      opacity: 1 !important;
      visibility: visible !important;
      -webkit-transition: opacity 600ms, visibility 600ms;
      transition: opacity 600ms linear, visibility 600ms linear;
    }
  }

  .category-courses-bottom {
    opacity: 1;
    visibility: visible;
    transition: all 0.7s ease-in;
  }

  & > .course-details-container {
    opacity: 1;
    visibility: visible;
    transition: all 0.7s ease-in;
  }

  .content-hide {
    opacity: 0 !important;
    visibility: hidden !important;
  }

  .content-visible {
    visibility: visible !important;
    opacity: 1 !important;
    -webkit-transition: opacity 600ms, visibility 600ms;
    transition: opacity 600ms linear, visibility 600ms linear;
  }

  .videoButton {
    position: absolute;
    font-size: auto;
    left: 50%;
    top: 50%;
    z-index: 10;
  }

  .playButton {
    content: "";
    background-image: url("../../assets/playButton.svg");
    background-size: 47px, 47px;
    width: 100px;
    height: 100px;
    margin-left: -47px;
    margin-top: -47px;
    background-size: contain;

    @media (max-width: 768px) {
      margin-left: -24px;
      margin-top: -24px;
      background-size: 47px, 47px;
      width: 47px;
      height: 47px;
      background-size: contain;
      & :hover {
        visibility: visible;
      }
    }
  }
}

.share-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='30' viewBox='0 0 20 22'%3E%3Cdefs%3E%3Cstyle%3E .a %7B fill: none; stroke: %234A4A4A; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; %7D %3C/style%3E%3C/defs%3E%3Cg transform='translate(-2 -1)'%3E%3Ccircle class='a' cx='3' cy='3' r='3' transform='translate(15 2)' /%3E%3Ccircle class='a' cx='3' cy='3' r='3' transform='translate(3 9)' /%3E%3Ccircle class='a' cx='3' cy='3' r='3' transform='translate(15 16)' /%3E%3Cline class='a' x2='6.83' y2='3.98' transform='translate(8.59 13.51)' /%3E%3Cline class='a' x1='6.82' y2='3.98' transform='translate(8.59 6.51)' /%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  width: 28px;
  height: 30px;
  &:hover {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='30' viewBox='0 0 20 22'%3E%3Cdefs%3E%3Cstyle%3E .a %7B fill: none; stroke: %23000000; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; %7D %3C/style%3E%3C/defs%3E%3Cg transform='translate(-2 -1)'%3E%3Ccircle class='a' cx='3' cy='3' r='3' transform='translate(15 2)' /%3E%3Ccircle class='a' cx='3' cy='3' r='3' transform='translate(3 9)' /%3E%3Ccircle class='a' cx='3' cy='3' r='3' transform='translate(15 16)' /%3E%3Cline class='a' x2='6.83' y2='3.98' transform='translate(8.59 13.51)' /%3E%3Cline class='a' x1='6.82' y2='3.98' transform='translate(8.59 6.51)' /%3E%3C/g%3E%3C/svg%3E");
  }
}

.course-actions {
  transition: all 0.2s ease-in;
  cursor: pointer;
  &:hover {
    color: black;
    transform: scale(1.1);
  }
}
