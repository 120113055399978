$background: #bbd8d8;
$primary: rgba(237, 57, 70, 0.7);
.search-modal {
  .field {
    position: absolute;
    top: 5%;
    width: 50% !important;

    input {
      &::placeholder {
        color: $primary;
      }
      &::-ms-input-placeholder {
        color: $primary;
      }
      box-shadow: 0 1px 1px rgba($background, 0.6),
        0 3px 3px rgba($background, 0.4), 0 8px 16px rgba($background, 0.3);
    }

    @media (max-width: 768px) {
      top: 6%;
      width: 90% !important;
    }
  }

  .modal-card {
    top: 5%;
    border-radius: 6px;
    width: 80% !important;
    max-height: 800px;
    @media (max-width: 768px) {
      top: 11%;
      width: 95% !important;
    }
    .modal-card-body {
      transition: height 1s ease-in;
      border-radius: 6px;
      max-height: 90vh;
    }
  }

  .modal-close {
    &::before {
      background-color: $primary;
    }
    &::after {
      background-color: $primary;
    }
    &:hover {
      background-color: white;
    }
  }
  control.is-loading::after {
    left: 0.625em !important;
  }
}
