.first-section {
  margin: 10rem auto 5rem;
  h3 {
    line-height: 1.5em;
  }
  image {
    width: 100%;
    margin: auto;
    justify-content: center;
    flex-direction: column;
  }
  @media (max-width: 1023px) {
    margin: 8rem auto 5rem;
  }
}
