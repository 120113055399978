.tv-slider {
  border-radius: 16px;
  .overlay-black {
    background-color: rgba($color: #000000, $alpha: 0.5);
    border-radius: 16px;
  }
  .content-top {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    height: 80vh;
    width: 100%;
    top: 50%;
    color: #ffffff;
    h1 {
      line-height: 45px;
    }
    p {
      max-width: 600px;
      color: rgb(219, 218, 218);
    }

    @media (max-width: 600px) {
      border-radius: 16px;
    }
  }
}

.tv-show-card {
  overflow-x: hidden;
  border-radius: "16px";
  height: 100%;
  .tv-show-watch-now {
    background-color: #ed3947;
    width: 500px;
    border-radius: 35px 35px;
    cursor: pointer;
    &:hover {
      background-color: #ec2d3b;
    }
  }
  .media-content {
    overflow-x: unset;
  }
  .card-image {
    img {
      border-radius: 16px 16px 0px 0px;
    }
  }
}
