.modal {
  // justify-content: end;
  & .modal-card {
    margin-top: 5rem;
    border-radius: 16px;

    & .modal-card-head {
      border: none;
      & h3 {
        border-left: 5px solid #ed3946;
      }
    }

    & .modal-card-foot {
      border: none;
      justify-content: center;
      flex-direction: column;
    }

    @media (max-width: 768px) {
      margin-top: 1rem;
    }
  }

  .modal-card-body {
    &::-webkit-scrollbar {
      width: 0.3rem;
    }
    &::-webkit-scrollbar-track {
      background: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background: #ed3946;
    }
  }
}
