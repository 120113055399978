.enroll-now {
  transition: box-shadow 0.3s ease-in-out, transform 0.2s ease-in;
  // width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.enroll-now:hover {
  transform: scale(1.1);
  -webkit-box-shadow: 0px 0px 32px -6px rgba(237, 57, 70, 1) !important;
  -moz-box-shadow: 0px 0px 32px -6px rgba(237, 57, 70, 1) !important;
  box-shadow: 0px 0px 35px -6px rgba(237, 57, 70, 1) !important;
}

.watch-now {
  transition: box-shadow 0.3s ease-in-out, transform 0.2s ease-in;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.watch-now:hover {
  transform: scale(1.1);
  -webkit-box-shadow: 0px 0px 32px -6px #007aff !important;
  -moz-box-shadow: 0px 0px 32px -6px #007aff !important;
  box-shadow: 0px 0px 35px -6px #007aff !important;
}

.center-button {
  justify-content: center;
}
