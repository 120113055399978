:root {
  font-size: 17px;
  @media (max-width: 768px) {
    font-size: 15px;
  }
}

*,
*::after,
*::before {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit;
}
input,
textarea,
button,
select,
div,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body::-webkit-scrollbar {
  width: 0.5rem;
}
body::-webkit-scrollbar-track {
  background: #fff;
}
body::-webkit-scrollbar-thumb {
  background: #ed3946;
}

div:focus {
  outline: none;
}

body {
  box-sizing: border-box;
  // font-family: "Poppins", sans-serif;
  height: 100vh;
  // overflow-x: hidden;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;700&display=swap");
$primary: #ed3946;
$danger: #ed3946;
$info: #909cff;
$light: #fff;
// $family-sans-serif: "Cairo", sans-serif;
$modal-background-background-color: rgba(211, 216, 219, 0.9);
$modal-card-head-background-color: $light;
.mt-large {
  margin-top: 60px !important;
}
// @import "node_modules/bulma/bulma.sass";
@import "node_modules/bulma/sass/utilities/_all.sass";
@import "node_modules/bulma/sass/base/_all.sass";
@import "node_modules/bulma/sass/elements/container.sass";
@import "node_modules/bulma/sass/layout/hero.sass";
@import "node_modules/bulma/sass/grid/columns.sass";
@import "node_modules/bulma/sass/helpers/typography.sass";
@import "node_modules/bulma/sass/helpers/color.sass";
@import "node_modules/bulma/sass/elements/button.sass";
@import "node_modules/bulma/sass/components/navbar.sass";
@import "node_modules/bulma/sass/helpers/spacing.sass";
@import "node_modules/bulma/sass/helpers/visibility.sass";
@import "node_modules/bulma/sass/layout/footer.sass";
@import "node_modules/bulma/sass/components/level.sass";
@import "node_modules/bulma/sass/elements/image.sass";
@import "node_modules/bulma/sass/elements/content.sass";
@import "node_modules/bulma/sass/elements/form.sass";
@import "node_modules/bulma/sass/form/_all.sass";
@import "node_modules/bulma/sass/components/modal.sass";
@import "node_modules/bulma/sass/elements/box.sass";
@import "node_modules/bulma/sass/components/tabs.sass";
@import "node_modules/bulma/sass/elements/icon.sass";
@import "node_modules/bulma/sass/components/card.sass";
@import "node_modules/bulma/sass/elements/other.sass";
@import "node_modules/bulma/sass/elements/notification.sass";
@import "node_modules/bulma/sass/components/dropdown.sass";
@import "node_modules/bulma/sass/elements/box.sass";
@import "node_modules/bulma/sass/components/media.sass";
@import "node_modules/bulma/sass/elements/progress.sass";
@import "node_modules/bulma/sass/elements/title.sass";
@import "node_modules/placeholder-loading/src/scss/placeholder-loading.scss";

// @import "../../node_modules/bulma-pageloader/dist/css/bulma-pageloader.sass";
li {
  font-size: 14px;
  margin-left: 10px;
  list-style-type: circle;
  list-style-position: inside;
}
// .block-list {
//   max-height: 50vh;
//   overflow: hidden;
//   overflow-y: scroll;
//   li {
//     list-style-type: none;
//   }
// }

.total-round {
  border-radius: 50%;
}

.root {
  font-family: "Poppins", sans-serif;
}

.full__screen {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
}

.trailer_palyer {
  .video-js {
    overflow: hidden;
    border-radius: 16px;
    outline: none !important;
    user-select: none !important;
    background-color: #fff !important;
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-shadow: 0 1px 1px rgb(187 216 216 / 60%),
      0 3px 3px rgb(187 216 216 / 40%), 0 8px 16px rgb(187 216 216 / 30%);

    video {
      border-radius: 16px;
      outline: none;
      user-select: none;
      background-color: #fff;
      overflow: hidden;
    }

    .vjs-poster {
      background-size: cover;
    }
  }
}
