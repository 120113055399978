.second-section {
  img.arabic-girl {
    height: 100% !important;
  }
}

.right-data {
  overflow-x: hidden;
  margin: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  @media (max-width: 1023px) {
    align-items: center;
  }
}

.is-horizontal-center {
  justify-content: center;
}
