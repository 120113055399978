.navbar-start .navbar-item {
  margin: auto;
  border: none;
  span {
    font-size: 1em;
    color: #000;
  }
  &:focus {
    background-color: transparent !important;
  }

  &:hover {
    color: #000;
    background-color: transparent !important;

    span {
      border-bottom: 2px solid #ed3946;
      cursor: pointer;
    }
  }
}
// $background-3: #ecd3d5;
$background-3: #bbd8d8;
.search-button,
.language-button {
  // border-radius: 50%;
  box-shadow: 0 1px 1px rgba($background-3, 0.6),
    0 3px 3px rgba($background-3, 0.4), 0 8px 16px rgba($background-3, 0.3);
  &:focus {
    border-color: #dbdbdb;
  }
}

.navbar-menu {
  animation: navAnimOpen 0.2s ease-in-out;
}

@keyframes navAnimOpen {
  0% {
    display: none;
    opacity: 0;
    max-height: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 1;
    max-height: 396px;
  }
}

.navbar-burger span {
  height: 2px;
}
