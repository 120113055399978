.faq {
  .card {
    border-radius: 0px 0px 6px 6px;
  }

  .card-header {
    z-index: 9;
    transition: border-radius 1s;
    border-radius: 6px;
    &.active-header {
      transition: border-radius 1s;
      border-radius: 6px 6px 0 0;
    }
  }
}
